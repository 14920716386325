import React from 'react'
import carpetRoom from "../assets/WindowTwo.png"
import { AiFillCheckCircle } from 'react-icons/ai';
import "./SteamCleanTop.css";
import { Link } from 'react-router-dom';

const SteamCleanTopTwo = () => {
  return (
    
    <div className='carpet-wrapper carpet-wrapper-two'>
        <div className='image-carpet'>
            <img className="carpet-room" src={carpetRoom} alt="Carpet that has been steamed clean"/>
        </div> 
        <div className='carpet-description'>
        <h3 className='head-window-top'>Changing the script on blue collar workers</h3>
         <h6>Sick of booking a trade service and haveing your time taken for granted? At Sprucely we want to make our moms proud and yours, we will show up, treat your home with respect and actaully deliver the result your paying for. </h6>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Transparent pricing, no hidden hourly rates</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Same day and next day window clean service</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Top grade window cleaning Equipment</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Eco friendly products</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>no pre-inspection needed for final quote, in most cases</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Premium insurance cover</p>
            <p className='p-description-partners'>Proud partners</p>
            <p className='p-description custom-p'><span className='finger'>👇</span> Build your custom quote here <span className='finger'>👇</span></p>
          <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/SteamForm"><button className='p-description end-lease-link'>LIVE BOOKING AND PRICE</button></Link>
        
        </div>
    </div>
  )
}

export default SteamCleanTopTwo