import React from 'react'
import bnbVideo from "../assets/bnbdem.mp4"; // Ensure this is a .mp4 file
import { AiFillCheckCircle } from 'react-icons/ai';
import "./SteamCleanTop.css"
import { Link } from 'react-router-dom';

const Airbnbtop = () => {
  return (
    <div className='carpet-wrapper'>
        <div className='image-carpet'>
            {/* Replacing image with a muted video */}
            <video className="bnb-vid" src={bnbVideo} autoPlay loop muted playsInline preload="auto"></video>

        </div> 
        <div className='carpet-description'>
         <h2 className='rec-head rec-sec'>5 star end to turn-over cleaning service.</h2>
          <h6 className='rec-head '>Melbourne | Geelong | Mornington | Regional Vic | Great Ocean rd.</h6>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>5-star Airbnb cleaning service</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>10% off first clean, fast booking, and instant quote</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Free linen provided</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Boost your rating to 5 stars based on cleaning</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>No risk of bad reviews due to cleaning</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>5-star customer rating</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>We take on the operational headache</p>
            <p className='p-description custom-p'><span className='finger'>👇</span> Custom quote here + 10% discount <span className='finger'>👇</span></p>
            <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/BookingGeneral">
                <button className='p-description end-lease-link'>SEND QUOTE</button>
            </Link>
            

        </div>
        
    </div>
  )
}

export default Airbnbtop
