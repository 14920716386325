import React from 'react'
import carpetRoom from "../assets/picgentwo.png"
import rating from "../assets/ratingPic.png"
import { AiFillCheckCircle } from 'react-icons/ai';
import "./SteamCleanTop.css"
import { Link } from 'react-router-dom';

const GeneralCleanTop = () => {


  
  return (
    <div className='carpet-wrapper'>
        <div className='image-carpet'>
            <img className="carpet-room" src={carpetRoom} alt="Nice looking styled room with carpet"/>
        </div> 
        <div className='carpet-description'>
          <h2 className='rec-head head-window-top'>Clean it like you mean it.</h2>
          <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Fixed price cleaning, no sneaky hidden p/hr rates</p>
          <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Safe, trained and fully police checked cleaners</p>
          <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Zero hassle with our thorough cleaning process</p>
          <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>We love cleaning and live clean</p>
          <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Enjoy a 10% discount on your first clean</p>
          <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Affordable pricing starting from $110</p>
          <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Comprehensive cleaning checklist for your peace of mind</p>
          <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Serving homes across Melbourne</p>
          <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Consistently rated 5 stars by our satisfied customers</p>
          <p className='p-description custom-p'><span className='finger'>👇</span> Get a custom quote here and enjoy an additional 10% discount <span className='finger'>👇</span></p>
          <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/General-Cleaning-Melbourne-quote"><button className='p-description end-lease-link'>BOOK NOW</button></Link>
    
    <img className="rating-img" src={rating} alt="rating symbol"/>
</div>

        
    </div>
  )
}

export default GeneralCleanTop