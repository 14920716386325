import React from 'react';
import imageDry from '../assets/bathroomcheck.png'; // Import image1
import imageProTec from '../assets/picgenthree.png'; // Import image2
import imageStain from '../assets/livingpiccheck.png'; // Import image1

import './serviceInfoSection.css';

const ServiceInfoSectionGeneral = () => {
  const images = [
    { src: imageDry, alt: 'Bathroom checklist', title: 'General Cleaning Bathroom checklist', description: ['Clean shower & screen','Clean all cupboards inside and out', 'Clean toilets', 'Sink','vanity', 'bowl', 'bathtub and all surfaces', 'fittings'] },
    { src: imageProTec, alt: 'Kitchen checklist', title: 'General Cleaning Kitchen checklist', description: ['Clean all cupboards inside and out', 'Benches', 'General wipe oven', 'Wipe rangehood', 'Wipe stovetop', 'Splashback', 'Vacuum plus mop floor', 'fittings'] },
    { src: imageStain, alt: 'Living and Bedroom checklist', title: 'General Living and Bedroom checklist', description: ['Dust blinds','Vacuum and mop floors', 'Clean cabinets', 'Spot clean walls', 'Clean doors, fittings, cobwebs', 'Dust blinds', 'Clean internal windows plus frames'] },
    // Add more images here
  ];

  return (
    <div> 
      <h2 className='checklist-heading'>Melbourne General House Cleaning Checklist</h2>
      <p className='p-description p-end'>
         "Our home cleaning service offers a comprehensive checklist 
         to ensure every aspect of your home is thoroughly cleaned to 
         perfection. From meticulously deep-cleaning carpets to ensuring 
         kitchen surfaces are spotless, we leave no detail overlooked. 
         Our expert team pays special attention to high-traffic areas, 
         ensuring floors are pristine and walls are free from marks. 
         Additionally, we meticulously clean bathrooms, including tiles, 
         grout, and fixtures, to ensure they sparkle. With our tailored 
         approach, we maximize your satisfaction by meeting your specific 
         cleaning needs. Trust us to deliver a meticulous home cleaning 
         service that exceeds your expectations, leaving your 
         home impeccably clean and inviting."</p>
      <div className="service-info-section service-end-wrap">
        {images.map((image, index) => (
          <div key={index} className="service-item">
            <img src={image.src} alt={image.alt} />
            <h3 className='list-items'>{image.title}</h3>
            <div className="checklist-items">
              {image.description.map((item, itemIndex) => (
                <div key={itemIndex} className="checklist-item">
                  
                  <span className='list-items'><span className='list-items' style={{ color: 'green', marginRight: '5px' }}>✓</span>{item}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceInfoSectionGeneral;






