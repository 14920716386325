import React from 'react'
import carpetRoom from "../assets/mattfloor.png"
import { AiFillCheckCircle } from 'react-icons/ai';
import "./SteamCleanTop.css";
import { Link } from 'react-router-dom';

const GymTwo = () => {
  return (
    
    <div className='carpet-wrapper carpet-wrapper-two'>
        <div className='image-carpet'>
            <img className="carpet-room" src={carpetRoom} alt="Carpet that has been steamed clean"/>
        </div> 
        <div className='carpet-description'>
         <h6>Why Sprucely? We deliver the best cleaning experience at a the lowest price on a Premium level. We offer a fixed price gym cleaning service with no addtional hidden rates onsite.</h6>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Transparent pricing, no hidden hourly rates</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>No long term contracts</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Top grade cleaners and equipment</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>We know the fitness industry</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>We take care of the operational headache</p>
            <p className='p-description p-two-gym'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Premium insurance cover</p>
            <p className='p-description p-two-gym'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>We clean large health clubs to small studios</p>
            <p className='p-description p-two-gym'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>We live upto members high expectations</p>
            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/book-a-walk-through"><button className='p-description end-lease-link mar-p-gym gym-two'>GET A QUOTE</button></Link>
        
        </div>
    </div>
  )
}

export default GymTwo