import React from 'react';
import './EndOfLeaseCleaningPrices.css'; // Assuming you have a CSS file for styling

const EndOfLeaseCleaningPrices = () => {
  return (
    <div className="container">
      <h2>End of lease clean quote</h2>
      <p>
        When it comes to end of lease cleaning, the expenses can fluctuate based on various factors. Elements such as the dimensions of the property, its state, and the specific cleaning necessities can all impact the total expense. In Melbourne, the cost of end of lease cleaning can differ depending on these factors.
      </p>
      <p>
        It's always advisable to acquire a customized quote to ascertain the precise cost tailored to your specific requirements. At Sprucely Clean, we offer competitive rates for our professional move-out cleaning services, ensuring a comprehensive, top-notch cleaning that aligns with your expectations and budget.
      </p>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th className='t-heading'>Property</th>
              <th className='t-heading'>Cleaning Only</th>
              <th className='t-heading'>+ Carpet Cleaning</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Studio Apartment</td>
              <td>$237</td>
              <td>$320</td>
            </tr>
            <tr>
              <td>1 Bedroom</td>
              <td>from $247</td>
              <td>from $317</td>
            </tr>
            <tr>
              <td>2 Bedroom</td>
              <td>from $287</td>
              <td>from $357</td>
            </tr>
            <tr>
              <td>3 Bedroom</td>
              <td>from $400</td>
              <td>from $470</td>
            </tr>
            <tr>
              <td>4 Bedroom</td>
              <td>from $550</td>
              <td>from $790</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EndOfLeaseCleaningPrices;
