import React from 'react'
import carpetRoom from "../assets/whyUsPic.png"
import { AiFillCheckCircle } from 'react-icons/ai';
import "./SteamCleanTop.css";
import logoCraig from "../assets/craigLogo.png"
import { FaAirbnb } from "react-icons/fa";

const AirbnbTwo = () => {
  return (
    
    <div className='carpet-wrapper carpet-wrapper-two'>
        <div className='image-carpet'>
            <img className="carpet-room" src={carpetRoom} alt="Carpet that has been steamed clean"/>
        </div> 
        <div className='carpet-description'>
      
         <h2 className='rec-head'>We deliver the best cleaning experience at a the lowest price.</h2>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Transparent pricing, no hidden hourly rates</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Same day and next day airbnb cleaning service</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Top grade cleaners and equipment</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Eco friendly products</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>no pre-inspection needed for final quote</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Premium insurance cover</p>
            <p className='p-description-partners p-description-partners-bnb'>5 star airbnb reviews <FaAirbnb size={60} /></p>
            
  
        </div>
    </div>
  )
}

export default AirbnbTwo