import React from 'react'
import carpetRoom from "../assets/Window.png"
import rating from "../assets/ratingPic.png"
import { AiFillCheckCircle } from 'react-icons/ai';
import "./SteamCleanTop.css"
import { Link } from 'react-router-dom';

const SteamCleanTop = () => {
  return (
    <div className='carpet-wrapper'>
        <div className='image-carpet'>
            <img className="carpet-room window-p" src={carpetRoom} alt="Nice looking styled room with carpet"/>
        </div> 
        <div className='carpet-description'>
        <h3 className='head-window-top'>Clean it like you mean it</h3>
         <h6>At Sprucely, we’re dedicated to delivering exceptional service at an honest price. Our mission is to flip the script on 
         unreliable trades not showing, and deliver a fun service with a high-end outcome.
         . </h6>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>General window cleaning service</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Commercial window cleaning service</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Single of multi level homes</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Commercial buildings upto 5 levels</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Dont risk it with unreliable trades</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>We show up and get the job done</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Delivering a service out moms would be proud of</p>
            <p className='p-description custom-p'><span className='finger'>👇</span> Build your custom quote here <span className='finger'>👇</span></p>
            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/SteamForm"><button className='p-description end-lease-link'>LIVE BOOKING AND PRICE</button></Link>
            
        <img className="rating-img" src={rating} alt="rating symbol"/>
        </div>
        
    </div>
  )
}

export default SteamCleanTop