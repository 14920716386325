import React from 'react'
import carpetRoom from "../assets/living.png"
import rating from "../assets/ratingPic.png"
import { AiFillCheckCircle } from 'react-icons/ai';
import logoCraig from "../assets/craigLogo.png"
import "./SteamCleanTop.css"

const EndOfLeaseCleanTop = () => {
  return (
    <div className='carpet-wrapper'>
        <div className='image-carpet'>
            <img className="carpet-room" src={carpetRoom} alt="Nice looking styled room"/>
        </div> 
        <div className='carpet-description'>
         <h6>Fill in your details below for an instant quote, our quotes are fixed price with no hidden fees or addtional charges on site, 
         so no need to stress about task price increaseing on site.</h6>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Fixed rate and no addtional hours charged</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Quality inspection upon completion</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>No need for pre-inspection</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Customer support</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>5 star customer rating</p>
            <p className='p-description'><span className='tick-gen-clean'>✔️</span><AiFillCheckCircle className="check-icon" size={30} color='#3AAFA9'/>Trusted provider with multiple agencies across melbourne</p>
            <p className='p-description-partners'>Proud partners</p>
            <img className="logo-agent" src={logoCraig} alt="logo of realestate agent"/>
          
        <img className="rating-img" src={rating} alt="rating symbol"/>
        </div>
    </div>
  )
}

export default EndOfLeaseCleanTop